
.hero-container {
    position: relative;
    width: 100%;
    height: 100vh;
    overflow: hidden;
}

.hero-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    opacity: 0.8; /* Adjust the opacity to control the fading effect */
}

.logo-overlay {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
}

.logo-image {
    width: 200px; /* Adjust the width as needed */
    height: auto; /* Automatically adjust the height while maintaining aspect ratio */
}

.hero-title-container {
    position: relative;
    z-index: 2;
    text-align: center;
}

.logo-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
}

.hero-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
}

.hero-title {
    font-size: 60px;
    margin-bottom: 20px;
    font-family: 'Your Desired Font', sans-serif;
    animation: animateTitle 2s ease-in-out forwards;
    text-shadow: 1px 1px 0 #000, -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000; /* Add a text shadow to create a border effect */
}

@keyframes animateTitle {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.app-store-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    animation: animateButtons 2s ease-in-out forwards;
}

@keyframes animateButtons {
    0% {
        opacity: 0;
        transform: translateX(-50px);
    }
    100% {
        opacity: 1;
        transform: translateX(0);
    }
}


.app-store-image {
    width: 120px;
    height: auto;
    margin: 0 30px;
    animation: pulse 4s 2; /* Make the animation last for 4 seconds and repeat twice */
}

.play-store-image {
    width: 140px;
    height: auto;
    margin: 0 30px;
    animation: pulse 4s 2; /* Make the animation last for 4 seconds and repeat twice */
}

@keyframes pulse {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.2);
    }
    100% {
        transform: scale(1);
    }
}


.scroll-down {
    position: absolute;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    animation: bounce 2s infinite;
    width: 24px;
    height: 24px;
    border-bottom: 2px solid #fff;
    border-right: 2px solid #fff;
}

@keyframes bounce {
    0% {
        transform: translate(-50%, 0) rotate(0deg);
    }
    50% {
        transform: translate(-50%, 10px) rotate(0deg);
    }
    100% {
        transform: translate(-50%, 0) rotate(0deg);
    }
}
