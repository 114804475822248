.footer {
    background-color: #1f3a4e;
    position: relative;
    padding: 20px 0;
}

.footer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 5px;
    background-color: #133b5c;
    transform: perspective(5px) rotateX(-1deg);
}

.footer-content {
    text-align: left;
    color: #fff;
    padding-left: 20px;
}

.logo-text {
    font-size: 20px;
    font-weight: bold;
}
