.animated-text-with-stars {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f1f1f1;
    padding-top: 5vh; /* Added 50% of the height */
}

.tiny{
    font-size: 10px;
}

.text-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.animated-text {
    font-size: 3rem;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

.animated-text .big-number {
    font-size: 5rem;
}

.animated-text .small-text {
    font-size: 2rem;
    margin-top: 5px;
}

.stars-container {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.animated-stars {
    display: inline-block;
    width: 100px;
    height: 20px;
    overflow: hidden;
    position: relative;
}

.animated-stars:before {
    content: '★★★★★';
    color: #fbb03b;
    position: absolute;
    top: 0;
    left: 0;
    white-space: nowrap;
    animation: animateStars 2s infinite linear;
}

.reviews-text {
    font-size: 2rem;
    color: #333;
    margin-top: 10px;
}

@media (max-width: 768px) {
    .animated-text-with-stars {
        padding: 10px;
    }

    .text-container {
        margin-bottom: 10px; /* Reduce margin-bottom for smaller spacing */
    }

    .animated-number-container {
        flex-direction: column; /* Stack the elements vertically */
        align-items: flex-start; /* Align elements to the left */
    }

    .animated-number {
        font-size: 36px;
        margin-right: 0; /* Remove margin-right */
        margin-bottom: 5px; /* Add margin-bottom for spacing */
    }

    .users-text {
        font-size: 18px;
    }

    .counting-text {
        font-size: 14px;
    }

    .stars-container {
        margin-top: 10px; /* Add smaller margin-top for spacing */
    }

    .animated-stars {
        width: 80px;
        height: 16px;
        margin-bottom: 8px;
    }

    .reviews-text {
        font-size: 14px;
    }
}
